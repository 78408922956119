<template>
	<div class="row">
		<form class="col-lg-4 col-md-6 col-sm-8 col-xs-12 mx-auto" @submit.prevent="attemptRegister()">
			<div class="mb-3">
				<label for="username" class="form-label">Username</label>
				<input type="text" class="form-control" id="username" v-model.trim="username" required>
			</div>
			<div class="mb-3">
				<label for="password" class="form-label">Password</label>
				<input type="password" class="form-control" id="password" v-model="password" required>
			</div>
			<div class="form-text text-danger" v-if="errorMessage">
				{{ errorMessage }}
			</div>
			<div class="mb-3">
				Already have an account?  <router-link :to="{ name: 'login' }">Login</router-link>.
			</div>
			<button type="submit" class="btn btn-primary">Register</button>
		</form>
	</div>
</template>

<script>
export default {
	data() {
		return {
			username: "",
			password: "",
			errorMessage: null
		};
	},
	methods: {
		async attemptRegister() {
			const result = await this.$store.dispatch("account/register", {
				username: this.username,
				password: this.password
			});

			if(result.error) {
				this.errorMessage = result.error;
			} else {
				this.$router.push({
					name: "home"
				});
			}
		}
	}
};
</script>
